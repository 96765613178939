import { memo } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import PropTypes from 'prop-types';

/**
 * @class
 * @param {string} pageTitle Specifies pageTitle of Head
 * @param {string} keywords Specifies keywords of Head
 * @param {string} pageDescription Specifies pageDescription of Head
 * @param {string} canonicalUrl Specifies canonicalUrl of Head
 * @param {string} viewport Specifies viewport of Head
 * @param {string} ogTitle Specifies ogTitle of Head
 * @param {string} ogUrl Specifies ogUrl of Head
 * @param {string} ogImage Specifies ogImage of Head
 * @param {string} ogDescription Specifies ogImage of Head
 *
 * @returns Page Head Component as per the data
 */

const PageHead = ({
  pageTitle,
  keywords,
  description,
  canonicalUrl,
  viewport,
  ogTitle,
  ogUrl,
  ogImage,
  ogDescription,
  hideFromSearchEngines
}) => {
  return (
    <>
      <Head role='heading'>
        <title>{pageTitle}</title>
        <meta
          name='viewport'
          content={viewport || 'initial-scale=1, width=device-width'}
        />
        <meta name='keywords' content={keywords} />
        <meta name='description' content={description} />
        <meta charSet='utf-8' />
        {hideFromSearchEngines && (
          <meta name='robots' content='noindex, nofollow' />
        )}
        <meta
          name='google-site-verification'
          content={process.env.NEXT_PUBLIC_GOOGLE_SV_ID}
        />

        <link rel='manifest' href='/manifest.json' />
        <link rel='canonical' href={canonicalUrl} />
        <meta property='og:title' content={ogTitle} />
        <meta property='og:description' content={ogDescription} />
        <meta property='og:image' content={ogImage} />
        <meta property='og:url' content={ogUrl} />

        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link rel='icon' sizes='16x16' href='/favicon-16x16.png' />
        <link rel='icon' sizes='32x32' href='/favicon-32x32.png' />
      </Head>
      <Script
        type='text/javascript'
        src='https://cdn.cookielaw.org/consent/94e89321-eca3-4107-84db-8d678bc0d721/OtAutoBlock.js'
      ></Script>
      <Script
        src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
        type='text/javascript'
        charset='UTF-8'
        data-domain-script='94e89321-eca3-4107-84db-8d678bc0d721'
      ></Script>
      {process.env.environment === 'prod' && (
        <>
          <Script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_GA4_ID}`}
          ></Script>
          <Script id='googleTagGA4' type='text/javascript'>
            {`window.dataLayer = window.dataLayer || []; 
        function gtag(){dataLayer.push(arguments);}
        gtag("js", new Date()); gtag("config", "${process.env.NEXT_PUBLIC_GOOGLE_GA4_ID}");`}
          </Script>
          <Script
            id='gtm'
            type='text/javascript'
          >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');`}</Script>
        </>
      )}
      <Script
        type='text/javascript'
        id='optwrapper'
      >{`function OptanonWrapper(){ }`}</Script>
    </>
  );
};

PageHead.defaultProps = {
  pageTitle: `${process.env.NEXT_PUBLIC_PROJECT_NAME}`,
  keywords: 'Energy Blue,Home energy',
  pageDescription: 'Home Energy Customer Experience',
  canonicalUrl: '/',
  ogTitle: 'Home Energy Customer Experience website',
  ogUrl: '/',
  ogImage: '',
  ogDescription: ''
};

PageHead.PropTypes = {
  pageTitle: PropTypes.string,
  keywords: PropTypes.string,
  description: PropTypes.string,
  canonicalUrl: PropTypes.string,
  viewport: PropTypes.string,
  ogTitle: PropTypes.string,
  ogUrl: PropTypes.string,
  ogImage: PropTypes.string,
  ogDescription: PropTypes.string,
  hideFromSearchEngines: PropTypes.bool
};

export default memo(PageHead);
